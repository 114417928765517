import './App.css';
import { HashRouter, Route, Switch } from "react-router-dom";

import ScrollToTop from './scroll-to-top';
import Heading from './heading/heading';
import Home from './home/home';
import Menus from './menus/menus';
import Buffets from './buffets/buffets';
import Lunches from './lunches/lunches';
import LunchesMenu from './lunches-menu/lunches-menu';
import About from './about/about';
import Nav from './nav/nav';
import PageNotFound from './404/404';
import { Component } from 'react';

class App extends Component {

  constructor (props) {
    super(props)
      this.state = {
        showNav: false
      }
    }

  toggleNav = () => {
    this.setState({
      showNav: !this.state.showNav
    })
  }
    
  render() {
    return (
      <HashRouter basename="/">
        <ScrollToTop />
        <div className="App">
          <Heading />
          <Switch>
            <Route path="/" exact render={(props) => <Home />} />
            <Route path="/menus/" exact render={(props) => <Menus />} />
            <Route path="/buffets/" exact render={(props) => <Buffets />} />
            <Route path="/lunches/" exact render={(props) => <LunchesMenu />} />
            <Route path="/lunches/:id" exact render={(props) => <Lunches match={props.match} />} />
            <Route path="/about/" exact render={(props) => <About />} />
            <Route path="*" render={(props) => <PageNotFound /> } />
          </Switch>
          <Nav toggleNav={this.toggleNav} showNav={this.state.showNav} /> 
        </div>
      </HashRouter>
    )
  }
}

export default App;
