import React, { Component } from 'react'

class PageNotFound extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="pagenotfound">
        Page Not Found
      </div>
    )
  }
}

export default PageNotFound
