import React, { Component } from 'react'
import { Link } from "react-router-dom";

import './heading.css';
import logo from '../assets/logo.svg';

class Heading extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="heading">
        <div className="header">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
        </div>
        <Link to="/about/" style={{textDecoration: "none"}}>
          <div className="banner">
            FREE local delivery, no minimum spend  
          </div>
        </Link>
      </div>
    )
  }
}

export default Heading
