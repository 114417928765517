import React, { Component } from 'react'
import { Link } from "react-router-dom";

import './nav.css';
import close from '../assets/close.svg';
import menu from '../assets/menu.svg';
import logo from '../assets/logo.svg';

class Nav extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="nav">
        <div className="nav-button" onClick={this.props.toggleNav}>
          <img className={this.props.showNav ? "nav-button-image-hidden" : "nav-button-image"} src={menu} alt="open navigation" />
          <img src={close} className={this.props.showNav ? "nav-button-image" : "nav-button-image-hidden"} alt="close navigation" />
        </div>
        <div className={this.props.showNav ? "nav" : "nav-hidden"}>
          <Link to="/" onClick={this.props.toggleNav}>
            <img src={logo} className="logo" alt="logo" />
          </Link>  
          <div className="nav-links">
            <Link to="/" onClick={this.props.toggleNav}>
              <div className="nav-link">Home</div>
            </Link>
            <Link to="/menus/" onClick={this.props.toggleNav}>
              <div className="nav-link">Menus</div>
            </Link>
            <Link to="/about/" onClick={this.props.toggleNav}>
              <div className="nav-link">About Us</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Nav