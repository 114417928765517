import React, { Component } from 'react'
import { Link } from "react-router-dom";

import './footer.css';
import { hours } from '../data/hours.json';

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

class Footer extends Component {

  constructor (props) {
    super(props)

    var bankHolidays = [
      new Date(2021, 7, 30), 
      new Date(2021, 11, 25),
      new Date(2021, 11, 26),
      new Date(2021, 11, 27),
      new Date(2021, 11, 28),
      new Date(2022, 0, 1),
      new Date(2022, 0, 3),
      new Date(2022, 3, 18),
      new Date(2022, 4, 2),
      new Date(2022, 5, 2),
      new Date(2022, 5, 3),
      new Date(2022, 7, 29),
      new Date(2022, 11, 25),
      new Date(2022, 11, 26),
      new Date(2022, 11, 27)
    ] 

    var d = new Date();
    var isBankHoliday = false

    bankHolidays.forEach (holidayDay => {
      if (datesAreOnSameDay(d, holidayDay)) {
        isBankHoliday = true
      }
    })

    var n = d.getDay().toString()

    if (isBankHoliday) {
      this.state = {
        hours: "Closed"
      }
    } else {
      this.state = {
        hours: hours.filter(function (e) {
          return e.id === n;
        })[0].hours
      }
    }
  }

  render () {
    return (
      <div id="footer">
        <div className="details">
          <a href="tel:01480 435535">01480 435535</a><br />
          <a href="https://goo.gl/maps/kiWx5whYhJbnptc4A" target='_blank' rel='noopener noreferrer'> 35a High Sreet, Huntingdon</a>
        </div>
        <div className="hours">
          <Link to="/about/">
            Today's hours:<br />
            {this.state.hours}
          </Link>
        </div>
      </div>
    )
  }
}

export default Footer