import React, { Component } from 'react'

import { lunches } from '../data/lunches.json';
import Footer from '../footer/footer';
import './lunches.css'

var lunchItem = {}

class Lunches extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lunchItem: {}
    }

    console.log(this.props)
    
    const { params } = this.props.match
    lunches.map((lunchMapItem) => { 
      if(lunchMapItem.id === params.id) {
        lunchItem = lunchMapItem
        console.log(lunchItem)
      }
    })

  }

  render () {
    return (
      <div id="lunches">
        <div className="lunches-container">
          <div className="menu-title">{lunchItem.title}</div>
          { lunchItem.menu !== undefined && lunchItem.menu.map( group => 
            <div key={group.title}>
              {group.title !== undefined && <div className="menu-subtitle">{group.title}</div>}
              <div className="menu-item-text" style={{marginBottom: "8px"}}>{group.info}</div>
              {group.items.map( item => 
                <div key={item.title} className="menu-card">
                  <div className="menu-item-title">{item.title}</div>
                  <div className="menu-item-text">{item.info}</div>
                  <div className="lunch-item-price">
                    {item.prices.map( price =>
                      <span key={price.item}>{price.item} <span className="lunch-item-price-bold">{price.price}</span></span>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Lunches
