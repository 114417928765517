import React, { Component } from 'react'

import './about.css';
import { hours } from '../data/hours.json';
import Footer from '../footer/footer';
import gradient from '../assets/gradient.png';
import platters from '../assets/platters.jpg';

class About extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="about">
        <img className="platters" src={platters} alt="" />
        <img className="gradient" src={gradient} alt="" />
        <div className="about-container">
          <div className="about-title">Lunches and Corporate Catering</div>
          <div className="about-text">Delicious and wholesome ingredients, freshly prepared and all handmade to order.</div>
          <div className="about-text">Buffets, baguettes, sandwiches, ciabattas, wraps, paninis, jacket potatoes and salad boxes.</div>
          <div className="about-text">Telephone orders welcome, <span className="about-text-bold">must be received by 11am.</span></div>
          <div className="about-text">Opening Times</div>
          <table className="" cellSpacing="0" cellPadding="0">
            <tbody>
              {hours.map ( hour =>
                <tr key={hour.day}>
                  <td>
                    <span className="about-text-opening">{hour.day}:</span>
                  </td> 
                  <td>
                    <span className="about-text-opening" style={{marginLeft: "12px"}}>{hour.hours}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }
}

export default About
