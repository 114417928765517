import React, { Component } from 'react'
import { Link } from "react-router-dom";

import home from '../assets/home.jpg';
import Footer from '../footer/footer';
import restaurant from '../assets/restaurant.svg';
import call from '../assets/call.svg';
import './home.css';

class Home extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="home">        
        <div className="home-button-container">
          <Link to="/menus/">
            <div className="home-button">
              <img className="home-button-image" src={restaurant} />
              <span className="home-button-text">Menus</span>
              </div>
          </Link>
          <a href="tel:01480 435535">
            <div className="home-button">
              <img className="home-button-image" src={call} />
              <span className="home-button-text">Order</span>
            </div>
          </a>
        </div>
        <div className="home-image-container">
          <img className="home-image" src={home} alt=""/>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Home
