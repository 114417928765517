import React, { Component } from 'react'
import { Link } from "react-router-dom";

import './menus.css';
import buffet from '../assets/buffet.jpg';
import lunches from '../assets/lunches.jpg';
import Footer from '../footer/footer';

class Menus extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="menus">
        <div className="menus-container">
          <div className="menu-title" style={{marginBottom:"8px"}}>Menus</div>
          <Link to="/buffets/">
            <div className="menu-button">
              <div className="menu-button-text">Buffets</div>
                <img src={buffet} alt="buffet" className="menu-button-image" />
            </div>
          </Link>
          <Link to="/lunches/">
            <div className="menu-button">
              <div className="menu-button-text">Lunches</div>
              <img src={lunches} alt="lunches" className="menu-button-image" />
            </div>
          </Link>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Menus
