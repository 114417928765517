import React, { Component } from 'react'

import { buffets } from '../data/buffets.json';
import Footer from '../footer/footer';
import './buffets.css'

class Buffets extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="buffets">
        <div className="buffets-container">
          <div className="menu-title">Buffets</div>
          <div className="menu-text">Paper plates and napkins will be provided.</div>
          <div className="menu-text">All prices plus VAT.</div>
          <div className="menu-text">Please return all platters and baskets or an additional charge will be made.</div>
          {buffets.map( group => 
            <div key={group.title}>
              <div className="menu-subtitle">{group.title}</div>
              <div>
                {group.items.map( item => 
                  <div className="menu-card" key={item.title}>
                    <div className="menu-item-title">{item.title}</div>
                    {item.items.map( buffetItem => 
                      <div key={buffetItem} className="menu-item-text" style={{maxWidth: "80%"}}>{buffetItem}</div>  
                    )}
                    <div className="buffet-item-price">
                      {item.price}<br/>
                      {item.perPerson ? "Per Person" : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Buffets
