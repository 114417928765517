import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { lunches } from '../data/lunches.json';
import Footer from '../footer/footer';
import './lunches-menu.css'

class LunchesMenu extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id="lunches">
        <div className="lunches-container">
          <div className="menu-title" style={{marginBottom:"8px"}}>Lunches</div>
          {lunches.map( group => 
            <div key={group.id} >
              <Link to={`/lunches/${group.id}`} style={{textDecoration: "none"}}>
                <div className="lunches-menu-button">
                  <div className="lunches-menu-button-text">{group.title}</div>
                  <i class="material-icons">chevron_right</i>
                </div>
              </Link>
            </div>
          )}
          <div className="menu-subtitle">Specials Board & Seasonal Specials</div>
          <p>For weekly and seasonal specials please see Specials Board in store.</p>
          <p>Now selling Fresh Fruit Smoothies, Soft Scoop Ice-Cream and a selection of Hot Drinks.</p>
          <p>For the selection we offer, come and see us or give us a call!</p>
        </div>
        <Footer />
      </div>
    )
  }
}

export default LunchesMenu
